import { createRouter, createWebHashHistory,createWebHistory} from 'vue-router'
import { getAuth, onAuthStateChanged } from "firebase/auth";
const routes = [
  {
    path: '/',
    name: 'homePage',
    meta: {
      title: 'homePage',
    },
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/property/:id',
    name: 'propertyDetail',
    meta: {
      title: 'Property Detail',
    },
    component: () => import('@/views/PropertyDetail.vue'),
  },
  {
    path: '/inspection/:id',
    name: 'inspectionDetail',
    meta: {
      title: 'inspection Detail',
    },
    component: () => import('@/views/InspectionDetail.vue'),
  },

  {
    path: '/lease',
    name: 'lease',
    meta: {
      title: 'lease',
    },
    component: () => import('@/views/LeasePage.vue'),
  },
  {
    path: '/sell',
    name: 'sell',
    meta: {
      title: 'sell',
    },
    component: () => import('@/views/LeasePage.vue'),
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    meta: {
      title: 'aboutus',
    },
    component: () => import('@/views/aboutusPage.vue'),
  },
  
  {
    path: '/register',
    name: 'register',
    meta: {
      title: 'register',
    },
    component: () => import('@/views/RegisterForm.vue'),
  },
  {
    path: '/forgetpassword',
    name: 'forgetpassword',
    meta: {
      title: 'forgetpassword',
    },
    component: () => import('@/views/forgetPassword.vue'),
  },
  {
    path: '/query',
    name: 'query',
    meta: {
      title: 'query',
    },
    component: () => import('@/views/queryPage.vue'),
  },
  {
    path: '/editproperty',
    name: 'editproperty',
    meta: {
      title: 'editproperty',
    },
    component: () => import('@/views/editProperty.vue'),
  },
  {
    path: '/:catchAll(.*)', // 捕获所有路径
    redirect: '/' // 重定向到首页
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'login',
    },
    component: () => import('@/views/LoginForm.vue'),
  },

]
//createWebHashHistory
const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if ((to.name === 'query' || to.name === 'editproperty' || to.name === 'inspectionDetail' ||to.name === 'register') && !user) {
      next({ name: 'login' }); 
    } else {
      next(); 
    }
  });
});
export default router
