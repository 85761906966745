import { createI18n } from 'vue-i18n';

// 定义翻译内容
const messages = {
  en: {
    changeLanguage: "中文",
    contactUs: "contact us",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    subject: "Subject",
    message: "Message",
    submit: "Submit",
    firstNamePlaceholder: "Enter First Name",
    lastNamePlaceholder: "Enter Last Name",
    phonePlaceholder: "Enter Phone Number",
    emailPlaceholder: "Enter Email",
    subjectPlaceholder: "Enter Subject",
    messagePlaceholder: "Enter Your Message",
    home: "Home",
    leaseRent: "For Lease",
    sell: "For Sale",
    aboutUs: "About us",
    contactUsTitle: "Contact Us",
    AusNumber: "Australia (+61)",
    ChiNumber: "China (+86)",
    thankYouMessage: "Thank you for contacting us!",
    countryCode: "Country Code:",
    phoneNumber: "Phone Number:",
    enterPhoneNumber: "0466672874(AUSTRALIA) or 15380583456(CHINA)",
    submitButton: "Submit",
    countryCodeLabel: "Country Code:",
    phoneNumberLabel: "Phone Number:",
    EnhanceService1: "Flexible management of rental cycles to ensure your maximum profitability.",
    enterPhoneNumberPlaceholder: "0466672874(AUSTRALIA) or 15380583456(CHINA)",
    ourStory: "Our Story",
    meHomesDescription: "Founded in 2019, Me Homes is a Melbourne company dedicated to property management. We are committed to providing high-quality property management and interior design services to maximise the profit of our property owners. Combining optimising the interior spaces and managing the property, we ensure our properties are outdistancing other comparable lettings in the market. Our goal is to achieve the high conversion rate of fast rental and long-term stable tenants. We manage over 100 properties in Melbourne CBD, Box Hill, Glen Waverley, Burwood, Mulgrave, Bundoora, Tarneit, Williams Landing, Thornbury, and ten other suburbs ongoingly. Sharpened by the challenging times during the pandemic, our team is ready to offer finer services with a positive attitude, maintain low vacancy rates and achieve the best returns.",
    letterFrom: "—A letter from Eva and Jane",
    firstNameRequired: "First name is required.",
    lastNameRequired: "Last name is required.",
    phoneRequired: "Phone number is required.",
    validPhone: "Phone number must be a valid Australian or Chinese mobile number!",
    emailRequired: "Email is required.",
    validEmail: "Email must be a valid email address.",
    subjectRequired: "Subject is required.",
    messageRequired: "Message is required.",
    //footer
    locationTitle: "Location",
    locationAddress: "Shop2/200 Victoria Street, Carlton VIC 3053",
    hoursTitle: "Hours",
    hoursWeek: "Weekdays\n10:30am - 5:30pm",
    hoursWeekend: "Weekend\nBy Appointment",
    contactTitle: "Contact",
    contactPhone: "0390418982",
    //home
    searchPlaceholder: "Enter address to search e.g. postcode, street name",
    searchButton: "Search",
    availableNowLease: "Available For Lease",
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    carParks: "Car Parks",
    leasePrice: "Lease Price (per week)",
    viewAll: "View All",
    availableNowSellbuy: "Available For Sale",
    priceRange: "Price Range",
    landSize: "Land size",
    status: "Status",
    //propertydetail
    propertyImageAlt: "Property image",
    propertyDetailTitle: "Property Detail",
    scheduleInspection: "enquiry",
    available: "Available",
    loadingPropertyDetails: "Loading property details...",
    firstnameRequired: "First name is required.",
    lastnameRequired: "Last name is required.",

    appointmentTimeRequired: "Appointment time is required.",
    PreferredappointmentTime: "Preferred appointment time",
    alreadyScheduledEmail: "You have already scheduled an inspection for this property using this email.",
    alreadyScheduledPhone: "You have already scheduled an inspection for this property using this phone number.",
    invalidEmailAddress: "Email must be a valid email address!",
    invalidPhoneNumber: "Phone number must be a valid Australian or Chinese mobile number!",
    inspectionTimeAfterAvailable: "Inspection time must be after today.",
    inspectionScheduledSuccess: "Inspection scheduled successfully, we will contact you later",
    errorSchedulingInspection: "Error scheduling inspection",
    //leasepage
    OnSale:"On Sale",
    Soldpro:"Sold Properties",
    Filter: "Filter",
    sortByPlaceholder: "Sort By",
    priceLowToHigh: "Price Low to High",
    priceHighToLow: "Price High to Low",
    availableDateEarliest: "Available Date Earliest To Latest",
    availableDateLatest: "Available Date Latest To Earliest",
    filterPropertiesTitle: "Filter Properties",
    minLeasePrice: "Min Lease Price",
    maxLeasePrice: "Max Lease Price",
    availableNow: "Available Now",
    inputPricePlaceholder: "type property price",
    notAvailableNow: "Not Available Now",
    cancel: "Cancel",
    inputPrice: "property price",
    applyFilters: "Apply Filters",
    loadMore: "Load More",
    bed: "Bed",
    bath: "Bath",
    car: "Car",
    now: "NOW",
    LoadMore: "Load More",
    resetFilters:"reset",
    //property detail
    Bedroom: "bedroom",
    Bathroom: "Bathroom",
    CarPark: "Car Park",
    Furnish: "Furnish",
    Study: "Studyroom",
    description: "description",
    lease_price_pw: "Price per week",
    kitchen: "Kitchen",
    sold: "sold",
    image: "image",
    layout: "layout",
    vedio: "video",
    perweek: "per week",
    PropertyDescription: "Property Description",
    PropertyFeatures: "Property Features",
    //home page
    Ourachievement: "Our achievement",
    achievementContent: "Based on our records, when landlords choose Me Homes Property, we are able to lease properties out more quickly. This is because we don't just rely on traditional rental advertisements, but proactively seek out more suitable tenants.",
    CurrentVacancyRate: "Current Vacancy Rate",
    Hours: "Hours",
    Quickesttimetorentaproperty: "Quickest time to rent a property",
    EnhanceService2: "Purchase of new and used furniture, installation of curtains, check-out cleaning, fire inspection, etc.",
    EnhanceService3: "Barrier-free Communication: Multilingual online communication platform, we speak: Cantonese, Mandarin, Japanese, and English.",
    EnhanceService4: "Multi-platform synchronised communication: Phone, Email, WeChat, WhatsApp, etc.",
    Service: "ENHANCE SERVICE & Service Include",
    PropertyOnboardingandPreparation: "Property Onboarding and Preparation",
    Property1: "Initial home inspection prior to accepting the property",
    Property2: "Preparation of Letting Authority",
    Property3: "Arranging advertising immediately after authorisation",
    Property4: "Arranging pre-letting inspection and detail photography",
    MarketingandViewingCoordination: "Marketing and Viewing Coordination",
    Marketing1: "Start of regular weekly openings ",
    Marketing2: "Provide weekly feedback on landlord openings and programme amendments",
    TenantScreeningandSelection: "Tenant Screening and Selection",
    Tenant: "Checking of tenant's ability to pay and rental history and selection of tenant",
    ONGOINGPROPERTYINSPECTIONSANDMANAGEMENT: "ONGOING PROPERTY INSPECTIONS AND MANAGEMENT",
    ongoing: "Arranging home inspections every six months and providing ongoing tenancy management services",
    FinancialReportingandStatements: "Financial Reporting and Statements",
    Financial: "Provide monthly rental statements and statements for the financial year",
    CopyRight: "© {year} MeHomesProperty. All rights reserved."
  },
  zh: {
    EnhanceService1: "灵活的租赁周期管理调配，保障您的最大利益。",
    changeLanguage: "English",
    contactUs: "联系我们",
    firstName: "名字",
    lastName: "姓氏",
    phone: "电话",
    email: "电子邮件",
    subject: "主题",
    message: "信息",
    submit: "提交",
    firstNamePlaceholder: "输入名字",
    lastNamePlaceholder: "输入姓氏",
    phonePlaceholder: "输入电话号码",
    emailPlaceholder: "输入电子邮件",
    subjectPlaceholder: "输入主题",
    messagePlaceholder: "输入您的信息",
    home: "首页",
    leaseRent: "租赁/出租",
    sell: "出售",
    aboutUs: "关于我们",
    resetFilters:"还原",
    contactUsTitle: "联系我们",
    thankYouMessage: "感谢您与我们联系！",
    countryCode: "国家代码：",
    phoneNumber: "电话号码：",
    AusNumber: "澳大利亚 (+61)",
    ChiNumber: "中国 (+86)",
    enterPhoneNumber: "输入电话号码 (例如：0466672874(澳大利亚) 或 15380583456(中国))",
    submitButton: "提交",
    countryCodeLabel: "国家代码：",
    phoneNumberLabel: "电话号码：",
    enterPhoneNumberPlaceholder: "输入电话号码 (例如：0466672874(澳大利亚) 或 15380583456(中国))",
    ourStory: "我们的故事",
    meHomesDescription: "成立于2019年，Me Homes是一家专注于房产管理的墨尔本公司。我们致力于提供高质量的房产管理和室内设计服务，以最大化我们业主的利润。通过优化室内空间和管理物业，我们确保我们的物业在市场上超越其他类似出租物业。我们的目标是实现快速出租和长期稳定租客的高转化率。我们在墨尔本CBD、Box Hill、Glen Waverley、Burwood、Mulgrave、Bundoora、Tarneit、Williams Landing、Thornbury以及其他十个郊区持续管理着超过100处物业。在疫情期间的挑战时刻磨练下，我们的团队准备以积极的态度提供更好的服务，保持低空置率并实现最佳回报。",
    letterFrom: "—来自Eva和Jane的一封信",
    firstNameRequired: "需要填写名字。",
    lastNameRequired: "需要填写姓氏。",
    phoneRequired: "需要填写电话号码。",
    validPhone: "电话号码必须是有效的澳大利亚或中国手机号码！",
    emailRequired: "需要填写电子邮件。",
    validEmail: "电子邮件必须是有效的邮箱地址。",
    subjectRequired: "需要填写主题。",
    messageRequired: "需要填写信息。",
    locationTitle: "地址",
    locationAddress: "Shop2/200 Victoria Street, Carlton VIC 3053",
    hoursTitle: "营业时间",
    hoursWeek: "工作日\n上午10:30 - 下午5:30",
    hoursWeekend: "周末\n预约",
    contactTitle: "联系方式",
    contactPhone: "0390418982",
    searchPlaceholder: "输入地址进行搜索，例如：邮编、街道名称",
    searchButton: "搜索",
    availableNowLease: "现在可租赁",
    bedrooms: "卧室",
    bathrooms: "浴室",
    carParks: "车位",
    leasePrice: "租赁价格（每周）",
    viewAll: "查看全部",
    availableNowSellbuy: "现在可出售",
    priceRange: "价格范围",
    landSize: "土地面积",
    status: "状态",
    propertyImageAlt: "房产图片",
    propertyDetailTitle: "房产详情",
    scheduleInspection: "咨询",
    available: "可入住",
    loadingPropertyDetails: "正在加载房产详情...",
    OnSale:"未售出",
    Soldpro:"已售出",
    firstnameRequired: "需要填写名字。",
    lastnameRequired: "需要填写姓氏。",
    PreferredappointmentTime: "偏好的预约时间",
    appointmentTimeRequired: "需要填写预约时间。",

    alreadyScheduledEmail: "您已使用此电子邮件预约过此物业的看房。",
    alreadyScheduledPhone: "您已使用此电话号码预约过此物业的看房。",
    invalidEmailAddress: "电子邮件必须是有效的邮箱地址！",
    invalidPhoneNumber: "电话号码必须是有效的澳大利亚或中国手机号码！",
    inspectionTimeAfterAvailable: "看房时间必须在今天之后。",
    inspectionScheduledSuccess: "看房预约成功，我们稍后会与您联系",
    errorSchedulingInspection: "预约看房时出错",
    Filter: "筛选",
    sortByPlaceholder: "排序方式",
    priceLowToHigh: "价格从低到高",
    priceHighToLow: "价格从高到低",
    availableDateEarliest: "可入住日期从早到晚",
    availableDateLatest: "可入住日期从晚到早",
    filterPropertiesTitle: "筛选物业",
    minLeasePrice: "最低租赁价格",
    maxLeasePrice: "最高租赁价格",
    inputPricePlaceholder: "输入房子价格",
    sold: '出售',
    availability: "可入住",
    any: "任意",
    availableNow: "现在可入住",
    notAvailableNow: "现在不可入住",
    cancel: "取消",
    inputPrice: "输入房子价格",
    applyFilters: "应用筛选",
    loadMore: "加载更多",
    bed: "卧室",
    bath: "浴室",
    car: "车位",
    now: "现在",
    Bedroom: "卧室",
    Bathroom: "浴室",
    CarPark: "车位",
    Furnish: "家具",
    LoadMore: "加载更多",
    Study: "书房",
    description: "附加信息",
    lease_price_pw: "每周租金",
    kitchen: "厨房",
    image: "图片",
    layout: "布局",
    vedio: "视频",
    perweek: "每周",
    PropertyDescription: "房源介绍",
    PropertyFeatures: "房源配置",
    //home page
    Ourachievement: "成果展示",
    achievementContent: "根据我们的记录，当房东选择 Me Homes Property时。我们会更加快速的把物业租赁出去 。这是因为我们不只是依靠传统的租赁广告，而是积极主动的寻找更多的好租户。",
    CurrentVacancyRate: "现阶段空置率",
    Hours: "小时",
    Quickesttimetorentaproperty: "最快招租记录",
    EnhanceService2: "新旧家具购置，窗帘安装，退房清洁，防火安全检查等。",
    EnhanceService3: "无障碍沟通：多语言在线沟通平台，语种包括：粤语，普通话，英语，日语和韩语。",
    EnhanceService4: "多平台同步沟通：电话，邮箱，微信，WhatsApp，小红书 留言必回，使命必达",
    Service: "增强服务&服务内容",
    PropertyOnboardingandPreparation: "物业入驻与准备",
    Property1: "接受物业前额初步验房",
    Property2: "租赁授权书准备以及签字",
    Property3: "授权后马上安排广告宣传",
    Property4: "安排出租前验房和细节拍照",
    MarketingandViewingCoordination: "市场营销和协调看房",
    Marketing1: "开始每周固定开放",
    Marketing2: "每周提供房东开放回馈和方案修正",
    TenantScreeningandSelection: "租户筛选和选择",
    Tenant: "对租户支付能力以及租赁历史排查和严选租客",
    ONGOINGPROPERTYINSPECTIONSANDMANAGEMENT: "持续的物业检查和管理",
    ongoing: "租客支付押金和租金，完成钥匙交付。每六个月安排验房，持续提供租房管理服务",
    FinancialReportingandStatements: "财务报告和报表",
    Financial: "租约借宿，安排退租检查报告。提供每个月租金报表和财年报表。",
    CopyRight: "© {year} MeHomesProperty。版权所有。"
  }
};


// 创建 i18n 实例并配置
const i18n = createI18n({
  legacy: false,
  locale: 'en', // 设置默认语言为英语
  fallbackLocale: 'en', // 当所选语言缺少翻译时，使用英语作为后备
  messages, // 设置翻译内容
});

export default i18n;
