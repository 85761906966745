// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { writeBatch } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsbglm4zhmB49EflPnGfcuBZc-Mrpn_ho",
  authDomain: "mehomesproperty.firebaseapp.com",
  projectId: "mehomesproperty",
  storageBucket: "mehomesproperty.appspot.com",
  messagingSenderId: "677201752243",
  appId: "1:677201752243:web:a48065224a60c1e038c0c6",
  measurementId: "G-WKDHLH2H37"
};
const createBatch = () => {
  return writeBatch(db);
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
// Initialize Firestore
const db = getFirestore(app);
const auth = getAuth(app);
export { db,auth,firebaseConfig, storage,createBatch };