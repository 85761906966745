import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'element-plus/theme-chalk/el-icon.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import i18n from '@/i18n.js';
import '@/firebase.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueEasyLightbox from 'vue-easy-lightbox'


//import store from './store';
const app: any = createApp(App)
app.use(i18n)
//app.use(store)
app.use(ElementPlus)
app.use(VueEasyLightbox)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(router).mount('#app')



